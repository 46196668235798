import { GET, activateCover, callApi, cancelTransaction } from "./store/Actions";

const MAX_CALLBACK_COUNT = 300;

export const fetchWithRetry = async (service, setStatus, setError, interval = 500, timeout = 2000, maxRetry = MAX_CALLBACK_COUNT) => {

    let count = 0;
    while (count < maxRetry) {
        try  {
            const response =  await callApi(GET, service, null, true)
            const {callbackUrl, status}  = response.data;
            await activateCover(status)
            if (status !== 'Started' && status !== 'Assessing') {
                window.removeEventListener("beforeunload", cancelTransaction);
                await sleep(timeout).then(id =>  clearTimeout(id))
                if (! isEmpty(callbackUrl))
                    window.location.replace(callbackUrl)
                else 
                if (! isEmpty(status))
                    setStatus(status)
                else 
                    setError();
                break;
            }
            await sleep(interval).then(id => clearTimeout(id))
        } catch (err) {
            setError()
            break;
        }
        count++
    }
}

export const isEmpty = (value) => {
    return (
      value === undefined ||
      value === null ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0)
    );
};

export const contains = (value, key) => {
    return (!isEmpty(value) && value.includes(key));
}

export const sleep = (delay) => {
  return new Promise(resolve =>  {
      const timer = setTimeout(() => resolve(timer), delay)
  })
}

export const floorTens = (value) => isEmpty(value) ? 0 : Math.floor(value / 10) * 10

export const equalsIgnoreCase = (str1, str2) => {
    if (isEmpty(str1) || isEmpty(str2))
        return str1 === str2
    return str1.toLowerCase() === str2.toLowerCase()
}